/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.carousel-indicators button {
  border-top: unset;
  width: unset;
  border-bottom: unset;
  border: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 5px;
  background-color: rgba(99, 65, 65, 0.4);
  height: 5px;
  width: 30px;}       

  .carousel-indicators .active {
  border-top: unset;
  border-bottom: unset;
  border: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 5px;
  background-color: rgb(255, 251, 251);
  height: 5px;
  width: 50px}

.carousel-control-prev-icon {
  background-image: url("./assets/images/svg/prev-icon.svg");
  margin-right: 100px;
}

.carousel-control-next-icon {
  background-image: url("./assets/images/svg/next-icon.svg");
  margin-left: 100px;
}

.carousel-item .img-fluid {
  width:100%;
  height:100%;
}

.form-check-input {
  margin-right: 1rem !important;
  margin-left: 0;
  transform: scale(1.2) !important;
  margin-bottom: auto;
  margin-top: 1% !important;
}

@media screen and (max-width: 860px){ /* when document is 860px or less */
  .carousel-item{
  width: 100%;
  }
}

@media only screen and (min-width: 300px) and (max-width: 700px)
{
.carousel-item {
    width: 100%;
    max-height: 380px;
    /* height: 130px !important; */
}
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/

.scrolled header { 
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  transition: all 0.3s ease; /* Add transition for smooth effect */ 
} 

#topbar {
  /*background: #106eea;*/
/*  background: #414447; */
    /* background: #fff2e3; */
/*  height: 40px;*/
  height: 24px;
  font-size: 14px;
  transition: all 0.5s;
  color: #fff;
  padding: 0;
  background: #e5f4ff; 
}

#topbar .contact-info i {
  font-style: normal;
/*  color: #fff;*/
}

#topbar .contact-info i a,
#topbar .contact-info i span {
  padding-left: 5px;
  color: #333 !important;
}

#topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
  transition: 0.3s;
}

#topbar .contact-info i a:hover {
  color: #fff;
  text-decoration: underline;
}

#topbar .social-links a {
  color: #333 !important;
  line-height: 0;
  transition: 0.3s;
  margin-left: 20px;
  font-size: 12px;
} 

.contact-info a {
    font-size: 12px;
}

#topbar .social-links a:hover {
  color: white;
}

/*#topbar .form-check.form-switch {
    align-items: center;
    display: flex;
}*/ 

#topbar .form-check.form-switch {
    align-items: center;
    display: flex;
    /*visibility: hidden;*/
}

/* top bar ends */


/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background-color: #fff2e3; 
transition: all 0.5s;
z-index: 997;
/*height: 86px;*/
box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

/*#header.fixed-top {
height: 70px;
}*/

#header .logo {
font-size: 16px;
margin: 0;
padding: 0;
line-height: 1.4; 
font-weight: 600;
letter-spacing: 0.8px;
font-family: var( --font-poppins);
}

#header .logo a {
color: #fff;
}

#header .logo a span {
color: #fff;
}

#header .logo img {
max-height: 80px;
width: 80px;
border-radius: 100%;
} 

.scrolled-offset {
margin-top: 70px;
}

#header .navbar {

/* background: #0059b9 !important;  */

/* background: #5a4ca7 !important; */
/* background-image: linear-gradient(316deg, #5052d8 0%, #923993 74%);  */

/* background-image: linear-gradient(316deg, #5052d8fa 0%, #92399366 74%);  */

background: #13608a !important;

}

header a.nav-link.scrollto.active, header a.nav-link.scrollto, header .dropdown a {
color: #fff; 
} 

.txt-red {
  /*color: #cd5c5c;*/
/*      color: #cd5c5c;*/
color: #ff329b;
}

.txt-green {
/*color: #008000;*/
/*color: #3568ab;*/
/*  color: #3f4a9f;*/
color: #0071c1;
}

.txt-highlight {
  color: #ffff00 !important;
  font-weight: 600; 
} 

.txt-inherit {
  color: #333;
}

.linkcards .card-body i {
display: block;
font-size: 22px;
}

.cusDrop ul {
  height: 290px;
overflow-y: auto;
}

img.showbanner {
background-repeat: no-repeat;
/* background-position: top; */
background-size: contain;
/* height: 182px; */
width: 100%;
}

/*header {
  background-image: url("../../assets/img/bg-black.jpg");
background-repeat: no-repeat;
background-position: top;
background-size: contain;
}*/

.j-center {
justify-content: center;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
/*background-color: #0b968b;*/
background: var( --bg-green);
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.login-btn {
  /* background: #ff6e31; */
  /* background: #ff9f31; */
  font-size: 14px !important;
  padding: 2px 12px 2px 0px !important;
  margin: 0;
  border-radius: 4px;
  transition: 0.3s !important; 
  /* animation: pulse-animation 2s infinite; */
  /* box-shadow: 0px 0px 1px 1px #0000001a;  */
}

@keyframes pulse-animation {
  0% {
/*    box-shadow: 0 0 0 0px rgb(173 121 61); */
box-shadow: 0 0 0 0px rgb(48 67 66);
  } 
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.navbar {
padding: 0;
}

.navbar ul {
margin: 0;
padding: 0;
display: flex;
list-style: none;
align-items: center;
}

.navbar li {
position: relative;
}

.navbar>ul>li {
white-space: nowrap;
/*  padding: 10px 0 10px 28px;*/
/*      padding: 10px 0 10px 18px; */
  padding: 5px 0 5px 18px;  
}

.navbar a,
.navbar a:focus {
display: flex;
align-items: center;
justify-content: space-between;
padding: 0px;
font-size: 15px;
/* font-weight: 600; */
color: #fff;
white-space: nowrap;
transition: 0.3s;
position: relative;
}

.navbar a i,
.navbar a:focus i {
font-size: 12px;
line-height: 0;
margin-left: 5px;
}

.navbar>ul>li>a:before {
content: "";
position: absolute;
width: 100%;
height: 2px;
bottom: -6px;
left: 0;
background-color: #ffffff;
visibility: hidden;
width: 0px;
transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover>a:before,
.navbar .active:before {
visibility: visible;
width: 100%;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
color: #ffffff; 
}

.navbar .dropdown ul {
display: block;
position: absolute;
left: 28px;
top: calc(100% + 30px);
margin: 0;
padding: 10px 0;
z-index: 99;
opacity: 0;
visibility: hidden;
background: #fff;
box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
transition: 0.3s;
}

.navbar .dropdown ul li {
min-width: 200px;
}

.navbar .dropdown ul a {
padding: 10px 20px;
font-weight: 400;
color: initial;
} 

.navbar .dropdown ul a i {
font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
color: #106eea;
}

.navbar .dropdown:hover>ul {
opacity: 1;
top: 100%;
visibility: visible;
}

.navbar .dropdown .dropdown ul {
top: 0;
left: calc(100% - 30px);
visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
opacity: 1;
top: 0;
left: 100%;
visibility: visible;
}

@media (max-width: 1366px) {
.navbar .dropdown .dropdown ul {
left: -90%;
}

.navbar .dropdown .dropdown:hover>ul {
left: -100%;
}
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
color: #222222;
font-size: 28px;
cursor: pointer;
display: none;
line-height: 0;
transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
color: #fff;
}

@media (max-width: 991px) {
.mobile-nav-toggle {
display: block;
}
.mobile-nav-toggle {
color: #fff !important;
}

.navbar ul {
display: none;
}
}

.navbar-mobile {
position: fixed;
overflow: hidden;
top: 0;
right: 0;
left: 0;
bottom: 0;
background: rgba(9, 9, 9, 0.9);
transition: 0.3s;
z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
position: absolute;
top: 15px;
right: 15px;
}

.navbar-mobile ul {
display: block;
position: absolute;
top: 55px;
right: 15px;
bottom: 15px;
left: 15px;
padding: 10px 0;
background-color: #fff;
overflow-y: auto;
transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
padding: 10px 20px;
font-size: 15px;
color: #222222;
}

.navbar-mobile>ul>li {
padding: 0;
}

.navbar-mobile a:hover:before,
.navbar-mobile li:hover>a:before,
.navbar-mobile .active:before {
visibility: hidden;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
color: #106eea;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
margin: 15px;
}

.navbar-mobile .dropdown ul {
position: static;
display: none;
margin: 10px 20px;
padding: 10px 0;
z-index: 99;
opacity: 1;
visibility: visible;
background: #fff;
box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
min-width: 200px;
}

.navbar-mobile .dropdown ul a {
padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
color: #106eea;
}

.navbar-mobile .dropdown>.dropdown-active {
display: block;
}

/* header ends */

/* marquee starts */
.home_marquee {
  background: #fffae5 !important;
}

.home_marquee a {
color: #37517e !important;
clear: both !important;
font-weight: 500;
}

/* marquee ends */

/* about starts */
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
}

.about .content ul i {
  background: #fff;
  box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
    font-size: 24px;
    /* color: #106eea; */
    /* color: #0b968b; */
    color: var( --text-green);
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted #b3d1fa;
    height: 48px;
    width: 48px;
    display: inline-block;
    line-height: 1;
    text-align: center;
    margin-right: 15px;
} 

.about .content ul h5 {
  font-size: 18px;
  color: #555555;
}

.about .content ul p {
  font-size: 15px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.section-title h2 {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
  padding: 8px 20px;
  margin: 0;
  background: #e7e9fd;
  color: #0059b9;
  display: inline-block;
  text-transform: capitalize;
  border-radius: 50px; 
}

.section-title h3 {
  margin: 15px 0 0 0;
  font-size: 26px;
}
.about h3, .services h3, .tnega h3 { 
  color: #548dc1;
  font-size: 22px;
  font-weight: 600; 
}

.about {
  /* background-image: url(./assets/images/pattern.png);
  background-attachment: fixed; 
  background-size: contain; */
  background-color: #f6f6ff;
  background-image: radial-gradient(#f74444 0.5px, #fafaff 0.5px);
  background-size: 10px 10px; 
} 

.box-shadow {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
    transition: all ease-in-out 0.4s;
    border-radius: 7px 11px;
}
/* about ends */

/* services starts */
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {
  background-color: #f3f5fa;
}
.services .icon-box {      
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
  transition: all ease-in-out 0.4s;
  background: #fff;
} 

/* .services .icon-box .icon {
  margin-bottom: 10px;
} */

.services .icon-box .icon i {
  color: #47b2e4;
  font-size: 36px;
  transition: 0.3s;
}

.services .icon-box h4 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 22px;
  color: #0059b9;
  border-radius: 7px 11px !important; 
} 

.services .icon-box h4 a {
  color: #37517e;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  transform: translateY(-10px);
}

.services .icon-box:hover h4 a {
  color: #47b2e4;
}
/* services ends */

/* tnega starts */
.img-view {
  text-align: center;
  margin: 0px auto;
  justify-content: center;
}
.img-view img {
  width: 389px;
  border-radius: 7px 11px;
}
/* tnega ends */

/* footer starts */
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #fff6e8;
/*  background: #fff;*/
padding: 0 0 30px 0;
color: #444444;
font-size: 14px;
/*  background: #f1f6fe;*/
/*    background: #fff6e8;*/
}

#footer .footer-newsletter {
padding: 50px 0;
background: #f1f6fe;
text-align: center;
font-size: 15px;
}

#footer .footer-newsletter h4 {
font-size: 24px;
margin: 0 0 20px 0;
padding: 0;
line-height: 1;
font-weight: 600;
}

#footer .footer-newsletter form {
margin-top: 30px;
background: #fff;
padding: 6px 10px;
position: relative;
border-radius: 4px;
box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
text-align: left;
}

#footer .footer-newsletter form input[type=email] {
border: 0;
padding: 4px 8px;
width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type=submit] {
position: absolute;
top: 0;
right: 0;
bottom: 0;
border: 0;
background: none;
font-size: 16px;
padding: 0 20px;
background: #106eea;
color: #fff;
transition: 0.3s;
border-radius: 0 4px 4px 0;
box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type=submit]:hover {
background: #0d58ba;
}

#footer .footer-top {
  padding: 30px 0 30px 0; 
/* padding: 60px 0 30px 0; */
/*  background: #fff;*/
}

#footer .footer-top .footer-contact {
margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 24px;
  margin: 0 0 15px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 500;
  color: #ff6e31 !important;
}

#footer .footer-top .footer-contact h3 span {
color: #106eea;
}

#footer .footer-top .footer-contact p {
font-size: 14px;
line-height: 24px;
margin-bottom: 0;
font-family: var( --font-roboto);
color: #777777 !important;
} 

#footer .footer-top h4 {
font-size: 16px;
font-weight: 500;
color: #ff6e31 !important;
position: relative;
padding-bottom: 12px;
}

#footer .footer-top .footer-links {
margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
list-style: none;
padding: 0;
margin: 0;
}

#footer .footer-top .footer-links ul i {
padding-right: 2px;
color: #106eea;
font-size: 18px;
line-height: 1;
}

#footer .footer-top .footer-links ul li {
padding: 10px 0;
display: flex;
align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
padding-top: 0;
}

#footer .footer-top .footer-links ul a {
color: #777777;
transition: 0.3s;
display: inline-block;
line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
text-decoration: none;
color: #106eea;
}

#footer .footer-top .social-links a {
font-size: 18px;
display: inline-block;
/*background: #106eea;*/
/*background: #0b968b;*/
background: var( --bg-green);
color: #fff;
line-height: 1;
padding: 8px 0;
margin-right: 4px;
border-radius: 4px;
text-align: center;
width: 36px;
height: 36px;
transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
/*background: #3b8af2;*/
/*background: #12aea2;*/
background: var( --bg-hover);
color: #fff;
text-decoration: none;
}

#footer .copyright {
text-align: center;
float: left;
}

#footer .credits {
float: right;
text-align: center;
font-size: 13px;
color: #444444;
}

.vCount span {
/*color: #0b968b;*/
color: var( --text-green);
}

@media (max-width: 768px) {

#footer .copyright,
#footer .credits {
float: none;
text-align: center;
padding: 2px 0;
}
}

.bg-twitter {
  background: #00acee !important;
}
.bg-fb {
  background: #3b5998 !important;
}
.bg-insta {
  background: #E4405F !important;
}
.bg-youtube {
  background: #CD201F !important;
} 
.c-initial {
  color: initial !important;
}
/* footer ends */

button.back-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #3f4a9f;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
  color: #fff;
}

.tnega {
/* background-image: url(./assets/images/tnega-banner.jpg);
    background-size: contain;
    background-repeat: no-repeat;  */
    background-image: url(./assets/images/tnega-banner.jpg);
    /* background-attachment: fixed; */
    background-size: cover;
    background-repeat: no-repeat; 
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.services, .about {
  background-image: url(./assets/images/pattern1.jpg);
  background-attachment: fixed;
  background-size: contain;
}  

.services .icon-box:hover::before {
  /* background: #3f4a9f;  */
  background: #13608a;
  top: 0;
  /* border-radius: 0px; */
}

.services .icon-box:hover i, .services .icon-box:hover h4 a, 
.services .icon-box:hover p, .services .icon-box:hover button {
  color: #fff !important;
}  

.services .icon-box::before {
  content: "";
  position: absolute;
  /* background: #3f4a9f; */
  background: #13608a;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.3s;
  z-index: -1;
} 
.pulse-wrap {
  animation: pulseone 1500ms infinite;
  border-radius: 4px 7px;
} 
@keyframes pulseone{
  0%{
     box-shadow: #ca4e4e 0 0 0 0; 
     border-radius: 4px 7px;
  }
  75%{
    box-shadow:#ff69b400 0 0 0 16px;
    border-radius: 4px 7px; 
  }
}

.txt-high {
  color: #ff6e31 !important;
  font-weight: 500;
}

/* toggle switch css starts */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 18px;
  background-color: #ccc;
  border-radius: 17px;
  cursor: pointer;
}

.toggle-switch.checked {
  background-color: #6ab04c; /* Background color when toggle is checked */
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.toggle-switch-inner {
  display: block;
  width: 50%;
  height: 100%;
  background-color: #fff;
  border-radius: 17px;
  transition: margin-left 0.3s ease-in-out;
}

.toggle-switch-switch {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  transition: left 0.3s ease-in-out;
}

.toggle-switch.checked .toggle-switch-inner {
  margin-left: 50%;
}

.toggle-switch.checked .toggle-switch-switch {
  left: 20px;
}  
/* toggle switch ends */

.main-wrap {
  overflow-x: hidden;
} 


.hero-wrap {
  position: relative;
}

.wlcm-txt {
  position: absolute;
    top: 2rem;
    left: 1rem;
}
.hero-wrap h1 {
  color: #ffff00 !important;
}

/* type write css */
/* Typewriter.css */

.typewriter {
  overflow: hidden;
  /* border-right: 0.08em solid orange; */
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 1s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor blinking */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}


.services img.showbanner {
  border-radius: 7px 13px;
}

.nav-center {
  justify-content: center !important;
    display: flex; 
    align-items: center;
}



/* styles.css */


/* styles.css */


.carousel {
  position: relative;
  width: 100%; /* Make the carousel occupy the full width of the screen */
  overflow: hidden;
  margin-bottom: 20px;
}

.slides {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  flex: 0 0 20%; /* Each slide occupies 20% of the carousel width */
}

.slide img {
  width: 100%; /* Make the image occupy the full width of its slide */
  height: auto; /* Maintain the aspect ratio */
  object-fit: cover;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.pagination {
  margin-top: 20px;
}

.pagination span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ddd;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.pagination span.active {
  background-color: #555;
}



.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* padding: 50px 0; */
  padding: 15px 0; 
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);
  margin: 0 15px 30px;
  overflow: hidden;
  border-radius: 7px 15px;
}
.ag-courses-item_link {
  display: block;
  padding: 15px 20px;
  overflow: hidden;
  position: relative;
  /* background: linear-gradient(120deg, #2355a8 0%, #103d52 100%); */
  background: linear-gradient(120deg, #1f4d9b 0%, #212a71 100%); 
  border: 0; 
  transition: 0.3s;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #FFF;
  transform: translate(0, -5px);
  overflow: hidden;
  border-radius: 7px 15px; 
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #FFF;
  z-index: 2;
  position: relative; 
}
.ag-courses-item_date-box {
  font-size: 16px;
  color: #FFF;
  z-index: 2;
  position: relative;
  text-align: center;
}
.ag-courses-item_date svg {
  width: 46px;
} 
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  /* background-color: #f9b234; */
  background-color: #cd3e94;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

}
@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
}

.banner_icon svg {
  width: 32px;
  height: 32px;
  font-weight: bold;
}
.logo-txt {
  font-size: 22px;
  color: #212f76;
  font-weight: 600;
}


/* hexa aniamte */

/* @keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} */

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateY(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateY(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateY(10px);
  }
}

.box-radius {
  border-radius: 11px 27px !important;
}

.hexagon-wrapper {
  display: flex;
  text-align: initial;
  width: 300px;
  height: 300px;
  cursor: pointer;
  transition: transform 250ms;
  animation: slideIn 1s ease forwards; /* Apply animation to the wrapper */
}

.hexagon-wrapper .hexagon:hover {
  transform: translateY(-10px);
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
}

.hexagon {
  position: relative;
  width: 46%;
  height: 80%;
  margin: auto;
  color: white;
  /* background: linear-gradient(-180deg, #d9eeff, #96d3ff); */
  background: linear-gradient(53deg, #212d75, #1f4794);
  display: flex;
  align-content: center;
  justify-content: center;
  transition: 0.5s;
  box-shadow: 0px 3px 3.5px rgba(0, 0, 0, 0.16);
  flex-direction: column;
  text-align: center;
}

.hexagon i, .hexagon svg {
  z-index: 1;
  color: transparent;
  background: linear-gradient(45deg, #a58fe9, #e37682);
  background-clip: text;
  -webkit-background-clip: text;
  width: 42px;
  height: 42px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 36%;
  margin-bottom: 15px; 
}

.hexagon:before,
.hexagon:after {
  position: absolute;
  content: "";
  background: inherit;
  height: 100%;
  width: 100%;
  border-radius: 0;
  transition: 0.5s;
  transform-origin: center;
}
.hexagon:before {
  transform: rotateZ(60deg);
}
.hexagon:after {
  transform: rotateZ(-60deg);
}
.hexagon:hover {
  border-radius: 50px;
  transition: 0.5s;
}
.hexagon:hover:before {
  border-radius: 50px;
  transition: 0.5s;
}
.hexagon:hover:after {
  border-radius: 50px;
  transition: 0.5s;
}

.hexa_text {
  z-index: 1;
  font-size: 20px;
  color: transparent;
  background: linear-gradient(45deg, #ffffff, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  text-align: center;
  font-weight: 400;
  line-height: 1.2;
}

/* hexa animate */

/* circle icon animate */
.education {
  --bg-color: #ffd861;
  --bg-color-light: #ffeeba;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
}


.card-animate {
  width: 220px;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease-out;
  text-decoration: none;
  box-shadow: 0px 3px 3.5px rgba(0, 0, 0, 0.16);
  border-radius: 7px 13px; 
}

.card-animate:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11), 0 24px 46px rgb(255 97 214 / 48%);
}

.card-animate:hover .overlay {
  transform: scale(4) translateZ(0);
}

.card-animate:hover .circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.card-animate:hover .circle:after {
  background: var(--bg-color-light);
}

.card-animate:hover p {
  color: var(--text-color-hover);
}

.card-animate:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0,0,0,0.11),
    0 15px 24px var(--box-shadow-color);
}

.card-animate p {
  font-size: 17px;
  color: #4C5656;
  margin-top: 30px;
  z-index: 1000;
  transition: color 0.3s ease-out;
}

.circle {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.circle:after {
  content: "";
  width: 118px;
  height: 118px;
  display: block;
  position: absolute;
  /* background: var(--bg-color); */
  background: #203984;
  border-radius: 50%;
  top: 7px;
  left: 7px;
  transition: opacity 0.3s ease-out;
}

.circle svg {
  z-index: 10000;
  transform: translateZ(0);
}

.overlay {
  width: 118px;
  position: absolute;
  height: 118px;
  border-radius: 50%;
  /* background: var(--bg-color); */
  background: #284394;
  top: 131px;
  left: 50px;
  z-index: 0;
  transition: transform 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* circle icon animate */

/* box animate */
.menu__container {
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  box-shadow: 0 10px 40px 2px rgba(0, 0, 0, 0.4);
  background: #203984;
  transform-origin: center center 0px;
  transition: all 0.5s ease-out;
  animation-name: animate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transform: perspective(1000px) rotateX(35deg) rotateY(4deg) rotateZ(-30deg);
  z-index: 1;
  border-radius: 4px 11px !important;
}
.menu__container:hover {
  animation: unset;
  margin-top: 18vh;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
.menu__container .menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  padding: 40px;
  border-radius: 4px 11px !important;
}
@-moz-keyframes animate {
  0% {
    margin-top: 20vh;
  }
  50% {
    margin-top: 18vh;
  }
  100% {
    margin-top: 20vh;
  }
}
@-webkit-keyframes animate {
  0% {
    margin-top: 20vh;
  }
  50% {
    margin-top: 18vh;
  }
  100% {
    margin-top: 20vh;
  }
}
@-o-keyframes animate {
  0% {
    margin-top: 20vh;
  }
  50% {
    margin-top: 18vh;
  }
  100% {
    margin-top: 20vh;
  }
}
@keyframes animate {
  0% {
    margin-top: 20vh;
  }
  50% {
    margin-top: 18vh;
  }
  100% {
    margin-top: 20vh;
  }
}

/* box animate */
